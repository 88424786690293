html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  box-sizing: border-box;

}
.activeNavbar {
  color: blue !important;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 4px;
}

.navUl {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

.navUl li a {
  text-decoration: none;
  color: #000;
}

.navUl li {
  list-style-type: none;
  box-sizing: border-box;
}

.logo {
  padding-right: 12px;
}

.parallax {
  width: 100%;
  height: 400px;
  background-image: url('./statics/bg.png');
  background-position: 0px  0px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.parallax-msg {
  z-index: 2;
}

.black {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
}

.parallax h1 {
  color: blue;
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  text-align: center;
  max-width: 1140px;
  position: relative;
}

.parallax h1 span {
  color: blue;
  overflow: hidden;
  display: block;
  /* animation: leftToRight 1 3s linear;
  animation-delay: 2s; */
  margin-left: auto;
  margin-right: auto;
}

.parallax h1 span a {
  text-decoration: none;
}

.parallax h1 span::after {
  content: '';
  display: block;
  width: 2px;
  height: 70%;
  background-color: #aebcd9;
  position: absolute;
  right: 0;
  top: 0;
}

@keyframes leftToRight {
  0% {
    width: 0
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.parallax p {
  font-size: 22px;
  color: #fff;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.card {
  width: 30%;
  background-color: #fff;
  padding: 30px 15px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card span {
  display: block;
  text-align: center;
  font-size: 50px;
  margin-bottom: 15px;
  color: blue;
}

.card h6 {
  margin-bottom: 15px;
  margin-top: 0;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.card p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  word-spacing: 1px;
  margin: 0;
}

.people h5 {
  font-size: 25px;
  /* font-weight: 200; */
}

.people img {
  border-radius: 50%;
  align-items: 'center';
  transition: 0.5s all ease-in-out; 
}

.people img:hover {
  transform: scale(1.2);
}

.people h6 {
  font-size: 18px;
  margin: 5px 0;
}

.info {
  padding: 0 15px;
}

.info a {
  font-size: 2em;
  color: #000;
}

.info p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  word-spacing: 1px;
  margin: 0;
}

.avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 258px;
  height: 258px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}

.portfolio-card {
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  background-color: #f7f7f7 !important;
}

.portfolio-tag {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  word-spacing: 1px;
  text-align: center;
}
.portfolio-title {
  text-align: center;
}

.portfolio-card ul {
  list-style-type: none;
  text-align: left;
  padding: 0;
}

.portfolio-card ul li {
  color: #00000a;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  border-radius: 5px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 5px;
}

.flip-card-front {
  background-color: #fff;
  color: black;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flip-card-back {
  background-color: #fff;
  transform: rotateY(180deg);
  overflow-y: scroll;
}

.services-card {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-grow: 1;
  border-radius: 5px;
  padding-bottom: 30px !important;
}

.flip-card {
  width: 30%;
  min-width: 250px;
  height: 250px;
  border-radius: 5px;
  cursor: pointer;
  border-style: solid;
  border-color: blue;
  border-width: 2px;
  margin-bottom: 15px;
}

.card-body {
  padding: 1.25rem;
  color: #00000a;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border-radius: 5px;
}

.card-text {
  font-size: 12px;
  line-height: 1.6;
  color: #666;
}

.flip-card-front span {
  font-size: 32px;
  color: blue;
}

.co-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;
}

.co-card {
  min-width: 198px;
  width: 17%;
  height: 292px;
  text-align: center;
  padding: 30px 15px;
  margin: 0px  15px 15px 15px;
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  box-sizing: border-box;
}

.customer-images {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.form {
  box-sizing: border-box;
}

.form > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  /* flex-grow: 1; */
}

.form input:not(.p-o-interest) {
  width: 30%;
  height: 40px;
  min-width: 320px;
  margin: 10px 0;
}

.form input, textarea {
  background-color: #f7f7f7;
  border: 0;
  border-radius: 5px;
  padding: 0 10px;
}

.p-o-interest {
  height: 40px;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  padding: 15px 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 160px;
  max-height: 160px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.input-button {
  display: flex !important;
  justify-content: center !important;
}

.button {
  background-color: blue !important;
  color: #fff;
  font-weight: bold;
  margin: 0 auto;
  align-self: center;
}

.button:hover {
  background-color: #fff  !important;
  color: blue;
}

.menu-icon {
  display: none;
  margin-right: 12px;
}

.menu-icon:hover {
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .cards-wrapper {
    display: block !important;
  }
  
  .card {
    width: 80% !important;
    margin: 0 auto;
  }

  .sm-md-card  {
    margin-bottom: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .nav-items {
    display: none !important;
  }

  .executives, .portfolio-card-wrapper {
    display: block !important;
  }

  .executives .info:nth-child(1) {
    padding-bottom: 40px !important;
  }
  .wrap {
    font-size: 40px;
  }

  .parallax-msg p {
    width: 90%;
    margin: 0 auto;
    font-size: 16px !important;
  }

  .parallax-msg {
    z-index: 2;
  }

  .mobile-nav {
    width: 100%;
    height: 400px;
    background-color: #fff;
    padding: 20px 0 20px 0;
    transition: height 2s ease-in-out;
  }
  .mobile-nav ul {
    list-style-type: none;
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
    box-sizing: border-box;
  }
  
  .mobile-nav ul li a {
    text-decoration: none;
    color: #000;
  }

  .mobile-nav ul li {
    padding: 10px 0;
  }
  .menu-icon {
    display: block;
  }
}

@media only screen and (max-width: 720px) {
  .co-card {
    width: 45% !important;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding: 80px 0;
}
